import { MachineLineItem } from "ui/src/types";
import { delay, InputText, updateMachineCart } from 'ui';
import { Item, Note, Card } from "../Components";
import * as styles from "./Steps.module.scss";
import { Fragment, useEffect, useLayoutEffect, useState } from "react";
import cn from 'classnames';
import { IStep, OrderSummary } from "./IStep";

function ShoppingCart({ cart, proceed, goBack }: IStep) {
    const translations = window.app.preloadState.translation;
    const [voucher, setVoucher] = useState("");
    const [voucherState, setVoucherState] = useState<boolean | null>(null);

    useLayoutEffect(() => {
        setVoucher(cart.promotionCode ?? "");
        setVoucherState(cart.promotionCode ? true : null);
    }, [cart.promotionCode]);

    const removeCode = async () => {
        const response = await fetch('/api/machinesales/cart/promotionCode', {
            method: 'DELETE',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        if (!response.ok) {
            throw new Error(await response.text());
        }
        setVoucherState(null);
    }

    const addCode = async () => {
        setVoucherState(null);
        const response = await fetch(`/api/machinesales/cart/promotionCode?promotionCode=${voucher}`, {
            method: 'PUT',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        switch (response.status) {
            case 409:
                // this shouldn't really happen?
            case 404:
                setVoucherState(false);
                break;
            default:
                setVoucher('');
                setVoucherState(true);
                break;
        }
    }

    useEffect(() => {
        if (cart.lineItems.length == 0) {
            window.location.reload();
        }
    }, [cart]);

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.pageTitle}>{translations["machineSalesCheckout.shoppingCart"]}</h1>
            <div className={styles.checkoutGrid}>
                <div className={cn(styles.checkoutGridMainArea, styles.itemList)}>
                    {cart.lineItems.map((product: MachineLineItem, index) => <Fragment key={product.code}>
                        {index > 0 && <hr />}
                        <Item productItem={product} />
                    </Fragment>
                )}
                </div>
                <div className={styles.checkoutGridDetails}>
                    <OrderSummary cart={cart} step={1} goBack={goBack} proceed={proceed} validateCart={() => Promise.resolve(true)} />
                    <Card>
                        <div>
                            {translations["machineSalesCheckout.doYouHaveVoucher"]}
                        </div>
                        <InputText label="" name="voucher" className={styles.noMargin} value={cart.promotionCode ?? voucher} readonly={!!cart.promotionCode} onChange={cart.promotionCode ? undefined : ev => setVoucher(ev.target.value)}>
                            {voucherState === true && <div className={styles.success}></div>}
                            {voucherState === false && <div className={styles.failure}></div>}
                        </InputText>
                        {cart.promotionCode
                            ? <button onClick={removeCode} className={cn("btn btn--outlined")}>{translations["machineSalesCheckout.removeCode"]}</button>
                            : <button onClick={addCode} className={cn("btn btn--outlined")}>{translations["machineSalesCheckout.addCode"]}</button>
                        }
                    </Card>
                    <Note className={styles.lgOrderTop} value={cart.note} />
                </div>
            </div>
        </div>
    );
}

export default ShoppingCart;
