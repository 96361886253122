import { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import * as styles from './Note.module.scss';
import cn from 'classnames';
import { debounce } from 'ui';

export const Note = ({ className, placeholder, value }: { className?: string, placeholder?: string, value: string }) => {
    const translations = window.app.preloadState.translation;
    const [note, setNote] = useState(value ?? "");
    const isFirstRender = useRef(true);

    const debouncedChange = useMemo(() => debounce(async (newNote: string) => {
        await fetch(`/api/machinesales/cart/note`, {
            method: 'PUT',
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify({
                Note: newNote
            })
        });
    }, 500), []);

    useLayoutEffect(() => {
        setNote(value ?? "");
    }, [value]);

    useEffect(() => {
        if (isFirstRender.current) {
            isFirstRender.current = false;
            return;
        }
        debouncedChange(note);
    }, [note]);

    return <div className={cn(styles.note, className)}>
        <p>{translations["machineSalesCheckout.doYouHaveANote"]}</p>
        <textarea rows={5} placeholder={placeholder ?? translations["machineSalesCheckout.enterTextHere"]} value={note} onChange={ev => setNote(ev.target.value)} />
    </div>;
}