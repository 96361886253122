import { IStep, OrderSummary } from "./IStep";
import * as styles from "./Steps.module.scss";
import cn from 'classnames';
import { MachineCartDelivery, MachineCartDeliveryMethod, MachineCartUserInformation, MachineLineItem, MessageRecord } from "ui/src/types";
import { Item, Note } from "../Components";
import { Fragment, useMemo } from "react";
import { InputCheckbox, InputSelect, InputText } from "ui";

export default function OrderConfirmation({ cart, proceed, goBack, moveToUserInformation, moveToDeliveryMethods, moveToCart, pickupBranches, deliveryAddresses, validationMessages }: IStep & { moveToUserInformation: () => void, moveToDeliveryMethods: () => void, moveToCart: () => void } & { pickupBranches: { label: string, value: string }[], deliveryAddresses: { label: string, value: string }[], validationMessages: MessageRecord[]}) {
    const translations = window.app.preloadState.translation;
    const loggedIn = window.app.preloadState.isLoggedIn;

    const userInformation = (name: keyof MachineCartUserInformation) => ({ value: cart.userInformation[name] ?? "" as any });
    const deliveryInformation = (name: keyof MachineCartDelivery) => ({ value: cart.deliveryMethod[name] ?? "" as any });

    const invoiceAddress = useMemo(() => [cart.userInformation.streetAndNumber, cart.userInformation.town, cart.userInformation.zipCode, cart.userInformation.country].filter(p => !!p).join(', '), []);

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.pageTitle}></h1>
            <div className={styles.checkoutGrid}>
                <div className={cn(styles.checkoutGridMainArea, styles.fourthStepForm)}>
                    <div>
                        <div className={styles.headingContainer}>
                            <h3>{translations["machineSalesCheckout.userInformation"]}</h3>
                            <span onClick={moveToUserInformation}>{translations["machineSalesCheckout.modify"]}</span>
                        </div>
                        {!loggedIn && <div className={styles.horizontalInputsGroup}>
                            <InputText readonly label={translations["machineSalesCheckout.firstName"]} name="firstName" {...userInformation("firstName")} />
                            <InputText readonly label={translations["machineSalesCheckout.lastName"]} name="lastName" {...userInformation("lastName")} />
                        </div>}
                        <InputText readonly label={translations["machineSalesCheckout.companyName"]} name="companyName" {...userInformation("companyName")} />

                        <div className={styles.horizontalInputsGroup}>
                            <InputText readonly label={translations["machineSalesCheckout.email"]} name="email" {...userInformation("emailAddress")} />
                            <InputText readonly label={translations["machineSalesCheckout.phoneNumber"]} name="phoneNumber" {...userInformation("phoneNumber")} />
                        </div>
                        <div className={styles.horizontalInputsGroup}>
                            <InputText readonly label={translations["machineSalesCheckout.referenceNumber"]} name="referenceNumber" {...userInformation("referenceNumber")} />
                            <InputText readonly label={translations["machineSalesCheckout.purchaseNumber"]} name="purchaseNumber" {...userInformation("purchaseNumber")} />
                        </div>
                        {loggedIn && <InputText readonly label={translations["machineSalesCheckout.invoiceAddress"]} name="invoiceAddress" value={invoiceAddress} disabled={true} />}
                        {!loggedIn && <>
                            <InputText readonly label={translations["machineSalesCheckout.streetAndNumber"]} name="streetAndNumber" {...userInformation("streetAndNumber")} />

                            <div className={styles.horizontalInputsGroup}>
                                <InputText readonly label={translations["machineSalesCheckout.town"]} name="town" {...userInformation("town")} />
                                <InputText readonly label={translations["machineSalesCheckout.zipCode"]} name="zipCode" {...userInformation("zipCode")} />
                            </div>
                            <InputSelect disabled={true} label={translations["machineSalesCheckout.country"]} name="country" options={[
                                { label: 'Sweden', value: 'se' },
                                { label: 'Germany', value: 'de' }
                            ]} {...userInformation("country")} />
                        </>
                        }

                        <Note className={styles.topMarginForTextArea} placeholder="" value={cart.note} />
                    </div>
                    <div>
                        <div className={styles.headingContainer}>
                            <h3>{translations["machineSalesCheckout.deliveryMethod"]}</h3>
                            <span onClick={moveToDeliveryMethods}>{translations["machineSalesCheckout.modify"]}</span>
                        </div>
                        {cart.deliveryMethod.selectedDeliveryMethodType === MachineCartDeliveryMethod.Delivery && <>
                            <InputCheckbox label={translations["machineSalesCheckout.delivery"]} name="deliveryMethod" checked={true} disabled={true} />
                                <div className={cn(styles.methodGroup, styles.disabled)}>
                                    {!loggedIn ? <>
                                        <div className={styles.horizontalInputsGroup}>
                                            <InputText readonly label={translations["machineSalesCheckout.firstName"]} name="firstName" {...deliveryInformation("firstName")} />
                                            <InputText readonly label={translations["machineSalesCheckout.lastName"]} name="lastName" {...deliveryInformation("lastName")} />
                                        </div>
                                        <InputText readonly label={translations["machineSalesCheckout.streetAndNumber"]} name="streetAndNumber" {...deliveryInformation("streetAndNumber")} />
                                        <div className={styles.horizontalInputsGroup}>
                                            <InputText readonly label={translations["machineSalesCheckout.town"]} name="town" {...deliveryInformation("town")} />
                                            <InputText readonly label={translations["machineSalesCheckout.zipCode"]} name="zipCode" {...deliveryInformation("zipCode")} />
                                        </div>
                                    <InputSelect disabled={true} label={translations["machineSalesCheckout.country"]} name="country" options={[
                                            { label: 'Sweden', value: 'se' },
                                            { label: 'Germany', value: 'de' }
                                        ]} {...deliveryInformation("country")} />
                                </> : <InputSelect disabled={true} label={translations["machineSalesCheckout.deliveryAddress"]} name="deliveryAddress" options={deliveryAddresses} {...deliveryInformation("deliveryAddressId")} />
                                }
                                </div>
                            </>
                        }
                        {cart.deliveryMethod.selectedDeliveryMethodType === MachineCartDeliveryMethod.PickUp && <>
                            <InputCheckbox label={translations["machineSalesCheckout.pickUpInStore"]} name="deliveryMethod" checked={true} />
                            <div className={cn(styles.methodGroup, styles.disabled)}>
                                <InputSelect disabled={true} label={translations["machineSalesCheckout.branchAddress"]} name="branchAddress" options={pickupBranches} {...deliveryInformation("deliveryBranchId")} />
                            </div>
                        </>}
                    </div>
                    <div>
                        <div className={styles.headingContainer}>
                            <h3 className={styles.shoppingCartTitle}>{translations["machineSalesCheckout.shoppingCart"]}</h3>
                            <span onClick={moveToCart}>{translations["machineSalesCheckout.modify"]}</span>
                        </div>
                        <div className={styles.itemList}>
                        {cart.lineItems.map((product: MachineLineItem, index) => <Fragment key={product.code}>
                                {index > 0 && <hr />}
                                <Item readonly productItem={product} />
                            </Fragment>
                            )}
                        </div>

                    </div>
                    {validationMessages?.length > 0 && <div className={styles.validationMessages }>
                        {validationMessages.map((messageRecord: MessageRecord) => (
                            <div className={styles.validationMessage} key={messageRecord.message}>
                                {messageRecord.message}
                            </div>
                        ))}
                    </div>
                    }
                </div>
                <div className={styles.checkoutGridDetails}>
                    <OrderSummary cart={cart} step={4} goBack={goBack} proceed={proceed} validateCart={() => Promise.resolve(true)} />
                </div>
            </div>
        </div>
    );
}
