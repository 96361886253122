import { InputCheckbox, InputPhone, InputSelect, InputText, debounce, delay, updateMachineCart } from "ui";
import * as styles from "./Steps.module.scss";
import cn from 'classnames';
import { Note } from "../Components";
import { IStep, OrderSummary, useValidation } from "./IStep";
import { MachineCartUserInformation } from "ui/src/types";
import { useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";

function UserInformation({ cart, proceed, goBack, enableNextStep }: IStep) {
    const translations = window.app.preloadState.translation;
    const [userInformation, setUserInformation] = useState(cart.userInformation);
    const [validationMessages, setValidationMessages] = useState<string[]>([]);
    const invoiceAddress = useMemo(() => [userInformation.streetAndNumber, userInformation.town, userInformation.zipCode, userInformation.country].filter(p => !!p).join(', '), [userInformation]);

    useLayoutEffect(() => {
        setUserInformation(cart.userInformation);
    }, [cart.userInformation]);

    const debouncedUpdate = useMemo(() => debounce(async (userInformation: MachineCartUserInformation) => {
        await fetch(loggedIn
            ? `/api/machinesales/cart/user-information/update-authorized`
            : `/api/machinesales/cart/user-information/update-anonymous`, {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            },
            body: JSON.stringify(userInformation)
        });
    }, 500), []);

    const loggedIn = window.app.preloadState.isLoggedIn;

    const fieldRequiredValidator = useValidation(translations["sharedTranslations.fieldIsRequired"]);
    const emailValidator = useValidation(translations["sharedTranslations.fieldIsRequired"], new RegExp(window.app.preloadState.machineSalesCheckout.emailRegex), translations["sharedTranslations.invalidFormat"]);

    const formHasAllRequiredFields = useMemo(() => !!(loggedIn
        ? userInformation.emailAddress && userInformation.phoneNumber
        : userInformation.emailAddress && userInformation.phoneNumber && userInformation.firstName && userInformation.lastName && userInformation.companyName && userInformation.streetAndNumber && userInformation.town && userInformation.zipCode && userInformation.country
    )?.length,
        [userInformation.emailAddress, userInformation.phoneNumber, userInformation.firstName, userInformation.lastName, userInformation.companyName, userInformation.streetAndNumber, userInformation.town, userInformation.zipCode, userInformation.country]);

    const updateUserInformation = useCallback((name: keyof MachineCartUserInformation) => {
        return {
            value: userInformation[name] ?? "",
            checked: userInformation[name],
            onChange: (ev: React.ChangeEvent<HTMLInputElement> | string | boolean) => {
                const newUserInformation = {
                    ...userInformation,
                    [name]: typeof ev === 'string' ? ev : typeof ev === 'boolean' ? ev : ev.target.value
                };
                setUserInformation(newUserInformation);
                debouncedUpdate(newUserInformation);
            }
        } as any
    }, [userInformation, debouncedUpdate]);

    useEffect(() => {
        const validated = !(fieldRequiredValidator.hasAnyErrors() || emailValidator.hasAnyErrors());
        enableNextStep?.(formHasAllRequiredFields && validated);
        },
        [formHasAllRequiredFields, fieldRequiredValidator.hasAnyErrors(), emailValidator.hasAnyErrors()]
    );

    const enabledToProceed = (!formHasAllRequiredFields || fieldRequiredValidator.hasAnyErrors() || emailValidator.hasAnyErrors())
        ? undefined
        : proceed;

    const validateUserInformation = async () => {
        const result = await fetch(`/api/machinesales/cart/user-information/validate`, {
            method: 'GET',
            headers: {
                "Swecon-Current-Language": window.app.preloadState.currentLanguage
            }
        });
        const data: { message: string }[] = await result.json();
        setValidationMessages(data.map(x => x.message));
        return data.length == 0;
    };

    return (
        <div className={styles.wrapper}>
            <h1 className={styles.pageTitle}>{translations["machineSalesCheckout.userInformation"]}</h1>
            <div className={styles.checkoutGrid}>
                <div className={cn(styles.checkoutGridMainArea, styles.secondStepForm)}>
                    {!loggedIn && <div className={styles.horizontalInputsGroup}>
                        <InputText label={translations["machineSalesCheckout.firstName"]} name="firstName" {...updateUserInformation("firstName")} {...fieldRequiredValidator('firstName')} />
                        <InputText label={translations["machineSalesCheckout.lastName"]} name="lastName" {...updateUserInformation("lastName")} {...fieldRequiredValidator('lastName')} />
                    </div>}
                    <InputText label={translations["machineSalesCheckout.companyName"]} disabled={loggedIn} name="companyName" {...updateUserInformation("companyName")} {...(loggedIn ? {} : fieldRequiredValidator('companyName'))} />

                    <div className={styles.horizontalInputsGroup}>
                        <InputText label={translations["machineSalesCheckout.email"]} name="email" {...updateUserInformation("emailAddress")} {...emailValidator('email')} />
                        <InputPhone label={translations["machineSalesCheckout.phoneNumber"]} name="phoneNumber" {...updateUserInformation("phoneNumber")} {...fieldRequiredValidator('phone')} />
                    </div>
                    <div className={styles.horizontalInputsGroup}>
                        <InputText label={translations["machineSalesCheckout.referenceNumber"]} name="referenceNumber" {...updateUserInformation("referenceNumber")} />
                        <InputText label={translations["machineSalesCheckout.purchaseNumber"]} name="purchaseNumber" {...updateUserInformation("purchaseNumber")} />
                    </div>
                    {loggedIn && <InputText label={translations["machineSalesCheckout.invoiceAddress"]} disabled={true} name="invoiceAddress" value={invoiceAddress} />}
                    {!loggedIn && <>
                        <InputText label={translations["machineSalesCheckout.streetAndNumber"]} name="streetAndNumber" {...updateUserInformation("streetAndNumber")} {...fieldRequiredValidator('streetAndNumber')} />
                        
                        <div className={styles.horizontalInputsGroup}>
                            <InputText label={translations["machineSalesCheckout.town"]} name="town" {...updateUserInformation("town")} {...fieldRequiredValidator('town')} />
                            <InputText label={translations["machineSalesCheckout.zipCode"]} name="zipCode" {...updateUserInformation("zipCode")} {...fieldRequiredValidator('zipCode')} />
                        </div>
                        <InputSelect label={translations["machineSalesCheckout.country"]} name="country" options={[
                            { label: 'Sweden', value: 'se' },
                            { label: 'Germany', value: 'de' }
                        ]} {...updateUserInformation("country")} {...fieldRequiredValidator('country')} />
                    </>
                    }

                    <Note className={styles.topMarginForTextArea} value={cart.note}/>

                    {!loggedIn && 
                        <InputCheckbox name="createSweconAccount" label={translations["machineSalesCheckout.createSweconAccountCheckobLabel"]} {...updateUserInformation("createSweconAccount")} />
                    }
                    <div className={styles.validationMessages}>
                        {validationMessages.map((message) => (
                            <div className={styles.validationMessage} key={message}>
                                {message}
                            </div>
                        ))}
                    </div>
                </div>
                <div className={styles.checkoutGridDetails}>
                    <OrderSummary cart={cart} step={2} goBack={goBack} proceed={enabledToProceed} validateCart={validateUserInformation} />
                </div>
            </div>
        </div>
    );
}

export default UserInformation;
